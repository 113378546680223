<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="layout--main" :class="[navbarClasses, footerClasses, {'app-page': isAppPage}]">

        <!-- <global-filter
            :navbarType="navbarType"
            :navbarColor="navbarColor"
            :footerType="footerType"
            :routerTransition="routerTransition"
            v-if="!disableCustomizer"
            :hideScrollToTop="hideScrollToTop"
            /> -->

        <!-- <vx-sidebar :sidebarItems="sidebarItems" :logo="require('@/assets/images/logo/logo.png')" title="Watchtower" parent=".layout--main" /> -->

        <div id="content-area" class="m-0" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">

            <div id="content-overlay"></div>

            <div class="content-wrapper">

                <the-navbar :navbarColor="navbarColor" :class="[{'text-white': isNavbarDark && !isThemeDark}, {'text-base': !isNavbarDark && isThemeDark}]" />

                <div class="router-view">
                    <div class="router-content" :class="{'mt-0': navbarType == 'hidden'}">
                        <transition :name="routerTransition">
                        <div class="router-header flex flex-wrap items-center mb-6" v-if="$route.meta.breadcrumb || $route.meta.pageTitle">
                            <div class="content-area__heading" :class="{'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light' : $route.meta.breadcrumb}">
                                <h2 class="mb-1">{{ routeTitle }}</h2>
                            </div>
                            <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" />
                            <div class="ml-auto flex flex-wrap items-center justify-between">
                            </div>
                        </div>
                        </transition>
                        <div class="content-area__content">
                            <back-to-top bottom="5%" visibleoffset="500" v-if="!hideScrollToTop">
                                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg" />
                            </back-to-top>
                            <transition :name="routerTransition" mode="out-in">
                                <router-view @changeRouteTitle="changeRouteTitle"></router-view>
                            </transition>
                        </div>
                    </div>
                </div>

            </div>

            <the-footer></the-footer>
        </div>
    </div>
</template>

<script>
	import VxSidebar from '@/layouts/components/vx-sidebar/VxSidebar.vue';
	import TheNavbar from '../components/TheNavbar.vue';
	import TheFooter from '../components/TheFooter.vue';
	import themeConfig from '@/../themeConfig.js';
	import sidebarItems from "@/layouts/components/vx-sidebar/sidebarItems.js";
	import BackToTop from 'vue-backtotop'
	import vSelect from 'vue-select'

	export default {
		data() {
			return {
				navbarType: themeConfig.navbarType || 'floating',
				navbarColor: themeConfig.navbarColor || '#fff',
				footerType: themeConfig.footerType || 'static',
				routerTransition: themeConfig.routerTransition || 'none',
				isNavbarDark: false,
				routeTitle: this.$route.meta.pageTitle,
				sidebarItems: sidebarItems,
				disableCustomizer: themeConfig.disableCustomizer,
				windowWidth: window.innerWidth, //width of windows
				hideScrollToTop: themeConfig.hideScrollToTop,
				disableThemeTour: themeConfig.disableThemeTour,
				tableFilter: {
					title: '',
					prompt: false
				},
				blacklistFilter: false
			}
		},
		watch: {
			'$route'() {
				this.routeTitle = this.$route.meta.pageTitle;
			},
			blacklistFilter(val) {
				this.blacklistedFilter(val)
			},
		},
		computed: {
			isAppPage() {
				if(this.$route.path.includes('/apps/')) return true
				else return false
			},
			isThemeDark() { return this.$store.state.theme == 'dark' },
			sidebarWidth() {
				return this.$store.state.sidebarWidth;
			},
			bodyOverlay() {
				return this.$store.state.bodyOverlay;
			},
			listingCount() {
				return this.$store.getters.getListingCount;
			},
			tableFilterValue() {
				return this.$store.getters.getTableFilterValue;
			},
			tableFilterOptions() {
				return this.$store.getters.getTableFilterOptions;
			},
			tableFilterActive() {
				return this.$store.getters.getTableFilterActive;
			},
			formattedFilteredCount() {
				if (this.listingCount['filtered']) {
					let count = this.listingCount['filtered'];

					if(count == 1) {
						return `1`;
					}
					else if((count - 1) % 1000 == 0) {
						return `${this.$options.filters.formatNumber(count - 1)}+`;
					}
					else {
						return this.$options.filters.formatNumber(this.listingCount['filtered']);
					}
				}
				else {
					return 0;
				}
			},
			contentAreaClass() {
				if(this.sidebarWidth == "default") return "content-area-default"
				else if(this.sidebarWidth == "reduced") return "content-area-reduced"
				else if(this.sidebarWidth) return "content-area-full"
			},
			navbarClasses() {
				return {
					'navbar-hidden': this.navbarType == 'hidden',
					'navbar-sticky': this.navbarType == 'sticky',
					'navbar-static': this.navbarType == 'static',
					'navbar-floating': this.navbarType == 'floating',
				}
			},
			footerClasses() {
				return {
					'footer-hidden': this.footerType == 'hidden',
					'footer-sticky': this.footerType == 'sticky',
					'footer-static': this.footerType == 'static',
				}
			},
			pageButtons() {
				return {
					refresh: this.$route.meta.buttons ? this.$route.meta.buttons.refresh : false,
					download: this.$route.meta.buttons ? this.$route.meta.buttons.download : false,
					filter: this.$route.meta.buttons ? this.$route.meta.buttons.filter : false,
					blacklist: this.$route.meta.buttons ? this.$route.meta.buttons.blacklist : false
				}
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			changeRouteTitle(title) {
				this.routeTitle = title;
			},
			handleWindowResize(event) {
				this.windowWidth = event.currentTarget.innerWidth;
				this.setSidebarWidth();
			},
			setSidebarWidth() {
				if (this.windowWidth < 1200) {
					this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)
					this.$store.dispatch('updateSidebarWidth', 'no-sidebar')
					this.disableThemeTour = true;
				}
				else if(this.windowWidth < 1200) {
					this.$store.dispatch('updateSidebarWidth', 'reduced')
				}
				else {
					this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true)
				}
			},
			toggleHideScrollToTop(val) {
				this.hideScrollToTop = val;
			}
		},
		components: {
			VxSidebar,
			TheNavbar,
			TheFooter,
			BackToTop,
			'v-select': vSelect,
		},
		created() {
			this.setSidebarWidth();
		}
	}
</script>
